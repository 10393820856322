<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`name`) ? 'filled' : ''">
              Nome
            </label>

            <a-input
              class="travel-input"
              placeholder="Nome"
              v-decorator="[
                `name`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`company_id`) ? 'filled' : ''">
              Empresa
            </label>

            <a-select
              class="travel-input"
              placeholder="Selecione uma empresa"
              optionFilterProp="txt"
              @change="getCompanyBranchesOnChange"
              v-decorator="[
                `company_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of companiesList"
                :key="index"
                :value="item.id"
                :txt="item.trading_name"
              >
                {{ item.id }} - {{ item.trading_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label
              :class="form.getFieldValue(`company_branch_id`) ? 'filled' : ''"
            >
              Filial
            </label>

            <a-select
              class="travel-input"
              placeholder="Selecione uma filial"
              optionFilterProp="txt"
              :disabled="companyBranchesList.length == 0"
              v-decorator="[
                `company_branch_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of companyBranchesList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`bank_id`) ? 'filled' : ''">
              Banco
            </label>

            <a-select
              class="travel-input"
              placeholder="Busque um banco"
              optionFilterProp="txt"
              v-decorator="[
                `bank_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of companyBanksList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.company_name }} ({{ item.account }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item class="travel-input-outer">
            <label :class="form.getFieldValue(`status`) ? 'filled' : ''">
              Status
            </label>

            <a-select
              class="travel-input"
              placeholder="Status"
              optionFilterProp="txt"
              v-decorator="[
                `status`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-select-option value="Ativo"> Ativo </a-select-option>
              <a-select-option value="Desativado"> Desativado </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-divider />

        <a-col class="a-center mb-30" :span="18">
          <div
            style="
              font-size: 12px !important;
              font-weight: 600;
              color: #666;
              margin-bottom: 4px;
              width: 70%;
            "
          >
            Taxas da máquina
          </div>

          <a-row
            class="mt-10 mb-10"
            style="font-size: 10px; font-weight: 600; letter-spacing: -0.5px"
          >
            <a-col :span="5"> Parcela </a-col>

            <a-col :span="5"> Valor (R$) </a-col>

            <a-col :span="5"> Porcentagem (%) </a-col>

            <a-col :span="4"> </a-col>
          </a-row>

          <a-row
            v-for="(tax, index) in taxesRange"
            :key="index"
            class="mb-10"
            :gutter="20"
          >
            <a-col :span="5">
              <font style="position: relative; top: 11px; font-weight: 600">
                {{ tax.id }}
              </font>
            </a-col>

            <a-col class="a-center" :span="5">
              <a-form-item class="mb-0">
                <a-form-item class="mb-0">
                  <a-input
                    class="travel-input"
                    v-currency="{
                      currency: {
                        prefix: 'R$ ',
                        suffix: '',
                      },
                      distractionFree: false,
                      valueAsInteger: false,
                      precision: 2,
                      autoDecimalMode: false,
                      allowNegative: false,
                    }"
                    v-decorator="[
                      `tax_range_value_${tax.id}`,
                      {
                        initialValue: tax.value,
                      },
                    ]"
                    @change="updateTaxValue(tax.id, index)"
                  />
                </a-form-item>
              </a-form-item>
            </a-col>

            <a-col :span="5">
              <a-form-item class="mb-0">
                <a-input
                  class="travel-input"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '%',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: false,
                    allowNegative: false,
                  }"
                  v-decorator="[
                    `tax_range_percentage_${tax.id}`,
                    {
                      initialValue: tax.percentage,
                    },
                  ]"
                  @change="updateTaxValue(tax.id, index)"
                />
              </a-form-item>
            </a-col>

            <a-col class="a-center" :span="4">
              <a-button
                class="mt-5"
                v-if="tax.id === taxesRange.length && taxesRange.length > 0"
                size="small"
                type="danger"
                shape="circle"
                icon="delete"
                @click="removeTaxRange(tax.id)"
              >
              </a-button>
            </a-col>
          </a-row>

          <a-button
            @click="addTaxRange(taxesRange.length)"
            :disabled="taxesRange.length < 12 ? false : true"
            class="mt-5"
            size="small"
            style="font-size: 12px"
            >Adicionar taxa</a-button
          >

          <a-form-item style="display: none">
            <a-input
              v-decorator="[
                `taxes_range`,
                { initialValue: JSON.stringify(taxesRange) },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-divider />

        <a-col class="a-center mt-10" :span="24">
          <a-button
            size="large"
            type="primary"
            :loading="loadingCreateBillingTicket"
            html-type="submit"
          >
            CRIAR BOLETO
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "EditBillingTicketDefaultData",
  props: {
    edit: Boolean,
    billingTicketId: Number,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loadingCreateBillingTicket: false,
      companiesList: [],
      companyBanksList: [],
      companyBranchesList: [],
      taxesRange: [],
      advanceFeeRange: [],
    };
  },
  mounted() {
    this.$http
      .get(`/company/list?page=1&per_page=100`)
      .then(({ data }) => {
        data;
        this.companiesList = data.data;
      })
      .catch(({ response }) => {
        response;
      });

    this.$http
      .get(`/acquirer/list?page=1&per_page=100`)
      .then(({ data }) => {
        data;
        this.acquirersList = data.data;
      })
      .catch(({ response }) => {
        response;
      });

    this.$http
      .get(`/company-bank-accounts/list?page=1&per_page=100&status=Ativo`)
      .then(({ data }) => {
        data;
        this.companyBanksList = data.data;
      })
      .catch(({ response }) => {
        response;
      });
  },
  methods: {
    addTaxRange(id) {
      this.taxesRange.push({
        id: id + 1,
        value: "",
        percentage: "",
      });
    },
    removeTaxRange(id) {
      for (var i in this.taxesRange) {
        if (this.taxesRange[i].id == id) {
          this.taxesRange.splice(i, 1);
          break;
        }
      }
    },
    updateTaxValue(id, i) {
      this.taxesRange.forEach((tax, index) => {
        if (index == i) {
          setTimeout(() => {
            tax.value = this.form.getFieldValue(`tax_range_value_${id}`);
            tax.percentage = this.form.getFieldValue(
              `tax_range_percentage_${id}`
            );
          }, 10);
        }
      });
    },

    getCompanyBranchesOnChange(id) {
      this.form.setFieldsValue({
        [`company_branch_id`]: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          data;
          this.companyBranchesList = data.data;
        })
        .catch(({ response }) => {
          response;
        });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        console.log(err, values);
        if (!err) {
          this.loadingCreateBillingTicket = true;

          this.$http
            .post("/billing-ticket/create", values)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.loadingCreateBillingTicket = false;
              this.$emit("listBillingTickets", true);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.loadingCreateBillingTicket = false;
            });
        }
      });
    },
  },
};
</script>
