var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`name`) ? 'filled' : ''},[_vm._v("\n            Nome\n          ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `name`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`company_id`) ? 'filled' : ''},[_vm._v("\n            Empresa\n          ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `company_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `company_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma empresa","optionFilterProp":"txt","show-search":""},on:{"change":_vm.getCompanyBranchesOnChange}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`company_branch_id`) ? 'filled' : ''},[_vm._v("\n            Filial\n          ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `company_branch_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `company_branch_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione uma filial","optionFilterProp":"txt","disabled":_vm.companyBranchesList.length == 0,"show-search":""}},_vm._l((_vm.companyBranchesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`bank_id`) ? 'filled' : ''},[_vm._v("\n            Banco\n          ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `bank_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `bank_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Busque um banco","optionFilterProp":"txt","show-search":""}},_vm._l((_vm.companyBanksList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.company_name)+" ("+_vm._s(item.account)+")\n            ")])}),1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`status`) ? 'filled' : ''},[_vm._v("\n            Status\n          ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `status`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `status`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Status","optionFilterProp":"txt"}},[_c('a-select-option',{attrs:{"value":"Ativo"}},[_vm._v(" Ativo ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"Desativado"}},[_vm._v(" Desativado ")])],1)],1)],1),_vm._v(" "),_c('a-divider'),_vm._v(" "),_c('a-col',{staticClass:"a-center mb-30",attrs:{"span":18}},[_c('div',{staticStyle:{"font-size":"12px !important","font-weight":"600","color":"#666","margin-bottom":"4px","width":"70%"}},[_vm._v("\n          Taxas da máquina\n        ")]),_vm._v(" "),_c('a-row',{staticClass:"mt-10 mb-10",staticStyle:{"font-size":"10px","font-weight":"600","letter-spacing":"-0.5px"}},[_c('a-col',{attrs:{"span":5}},[_vm._v(" Parcela ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Valor (R$) ")]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_vm._v(" Porcentagem (%) ")]),_vm._v(" "),_c('a-col',{attrs:{"span":4}})],1),_vm._v(" "),_vm._l((_vm.taxesRange),function(tax,index){return _c('a-row',{key:index,staticClass:"mb-10",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":5}},[_c('font',{staticStyle:{"position":"relative","top":"11px","font-weight":"600"}},[_vm._v("\n              "+_vm._s(tax.id)+"\n            ")])],1),_vm._v(" "),_c('a-col',{staticClass:"a-center",attrs:{"span":5}},[_c('a-form-item',{staticClass:"mb-0"},[_c('a-form-item',{staticClass:"mb-0"},[_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                    currency: {
                      prefix: 'R$ ',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: false,
                    allowNegative: false,
                  }),expression:"{\n                    currency: {\n                      prefix: 'R$ ',\n                      suffix: '',\n                    },\n                    distractionFree: false,\n                    valueAsInteger: false,\n                    precision: 2,\n                    autoDecimalMode: false,\n                    allowNegative: false,\n                  }"},{name:"decorator",rawName:"v-decorator",value:([
                    `tax_range_value_${tax.id}`,
                    {
                      initialValue: tax.value,
                    },
                  ]),expression:"[\n                    `tax_range_value_${tax.id}`,\n                    {\n                      initialValue: tax.value,\n                    },\n                  ]"}],staticClass:"travel-input",on:{"change":function($event){return _vm.updateTaxValue(tax.id, index)}}})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('a-form-item',{staticClass:"mb-0"},[_c('a-input',{directives:[{name:"currency",rawName:"v-currency",value:({
                  currency: {
                    prefix: '',
                    suffix: '%',
                  },
                  distractionFree: false,
                  valueAsInteger: false,
                  precision: 2,
                  autoDecimalMode: false,
                  allowNegative: false,
                }),expression:"{\n                  currency: {\n                    prefix: '',\n                    suffix: '%',\n                  },\n                  distractionFree: false,\n                  valueAsInteger: false,\n                  precision: 2,\n                  autoDecimalMode: false,\n                  allowNegative: false,\n                }"},{name:"decorator",rawName:"v-decorator",value:([
                  `tax_range_percentage_${tax.id}`,
                  {
                    initialValue: tax.percentage,
                  },
                ]),expression:"[\n                  `tax_range_percentage_${tax.id}`,\n                  {\n                    initialValue: tax.percentage,\n                  },\n                ]"}],staticClass:"travel-input",on:{"change":function($event){return _vm.updateTaxValue(tax.id, index)}}})],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-center",attrs:{"span":4}},[(tax.id === _vm.taxesRange.length && _vm.taxesRange.length > 0)?_c('a-button',{staticClass:"mt-5",attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeTaxRange(tax.id)}}}):_vm._e()],1)],1)}),_vm._v(" "),_c('a-button',{staticClass:"mt-5",staticStyle:{"font-size":"12px"},attrs:{"disabled":_vm.taxesRange.length < 12 ? false : true,"size":"small"},on:{"click":function($event){return _vm.addTaxRange(_vm.taxesRange.length)}}},[_vm._v("Adicionar taxa")]),_vm._v(" "),_c('a-form-item',{staticStyle:{"display":"none"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `taxes_range`,
              { initialValue: JSON.stringify(_vm.taxesRange) },
            ]),expression:"[\n              `taxes_range`,\n              { initialValue: JSON.stringify(taxesRange) },\n            ]"}]})],1)],2),_vm._v(" "),_c('a-divider'),_vm._v(" "),_c('a-col',{staticClass:"a-center mt-10",attrs:{"span":24}},[_c('a-button',{attrs:{"size":"large","type":"primary","loading":_vm.loadingCreateBillingTicket,"html-type":"submit"}},[_vm._v("\n          CRIAR BOLETO\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }